<template>
    <page
        id="main-showings-calendar"
        :title="translate('Showing:::Showings', {}, 2)"
        :status="page_status"
        :is-root="true"
        @statused="statused"
    >
        <showings-calendar />
    </page>
</template>

<script type="text/javascript">
import { is_page } from "@/nibnut/mixins"

import { ShowingsCalendar } from "@/custom/components"

export default {
    mixins: [is_page],
    components: {
        ShowingsCalendar
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.nibnut-app.listing_showing-list {
    main > div {
        &, #content, #main-showings-calendar {
            height: 100%;
        }
        #main-showings-calendar, .showings-calendar {
            display: flex;
            flex-direction: column;
            .showings-calendar {
                flex: 1 0 1px;
            }
        }
    }
}
</style>
